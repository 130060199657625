import { useState } from 'react';
import ApiClient from './axiosSetup';
import { APIObj, AJAXError } from '@types';
import { AxiosError, GenericAbortSignal, ResponseType } from 'axios';

type Props = {
	endpoint: string | APIObj;
	headers?: { [key:string]: string };
	domain?: 'EOS' | 'ShipmentAPI' | 'AdminAPI' | 'Inertia';
	avoidCache?: boolean;
}
type FullProps = Props & {
	method: 'GET' | 'get' | 'POST' | 'post' | 'PATCH' | 'patch' | 'DELETE' | 'delete';
}
type APICallProps = {
	payload?: any;
	params?: { [key:string]: string } | URLSearchParams;
	routes?: string[];
	overrideUrl?: string;
	signal?: GenericAbortSignal;
	headers?: { [key: string]: string };
	responseType?: ResponseType;
}

const useAPI = (props:FullProps) => {
	const {
		endpoint,
		method,
		headers = {},
		domain = 'EOS',
		avoidCache = false
	} = props;
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [retData, setRetData] = useState<any | null>(null);
	const [error, setError] = useState<Error | AxiosError | AJAXError | null>(null);
	const fullHeaders = { ...headers }
	if (!fullHeaders['Accept']) {
		fullHeaders['Accept'] = 'application/json';
	}
	if (avoidCache) {
		fullHeaders['X-AvoidCache'] = 'avoid';
	}

	const formatURL = (props?:APICallProps): string => {
		let url = typeof endpoint === 'string' ? endpoint : endpoint.url;
		if (props && props.routes && props.routes.length) {
			for (let i=0, l=props.routes.length; i<l; ++i) {
				url = url.replace(`{${i + 1}}`, props.routes[i]);
			}
		}
		if (domain !== 'EOS') {
			url = `/${domain.toLowerCase()}${url}`;
		}
		if (props && props.overrideUrl) url = props.overrideUrl;
		return url;
	}

	const callAPI = async (props?: APICallProps) => {
		setIsLoading(true);
		try {
			const res = await ApiClient.request({
				url: formatURL(props),
				method,
				data: props?.payload || {},
				params: props?.params || {},
				headers: { ...fullHeaders, ...props?.headers },
				signal: props?.signal,
				responseType: props?.responseType
			});
			setRetData(res.data as any);
			return {
				data: res.data,
				error: null
			}
		} catch (e) {
			if ((e as AJAXError).code && (e as AJAXError).code === 'ERR_CANCELED') return;
			// --> ignore network errors for CI Tests, need to come back to this 🤔
			if ((e as AJAXError).code && (e as AJAXError).code === 'ERR_NETWORK') return;
			setError(e as AJAXError);
			throw new Error((e as AJAXError).response?.data?.message ?? 'Error', { cause: e });
		} finally {
			setIsLoading(false)
		}
	}

	const getData = () => {
		if (!retData) return null;
		return retData as any;
	}

	const clearError = () => {
		setError(null);
	}

	const clearData = () => {
		setRetData(null);
	}

	return {
		isLoading,
		data: getData(),
		error,
		clearError,
		clearData,
		callAPI
	} as const
}

export const useGetAPI = (props:Props) => {
	const {
		endpoint,
		headers = {},
		domain = 'EOS',
		avoidCache = false
	} = props;
	const {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	} = useAPI({
		endpoint,
		method: 'GET',
		headers: { ...headers },
		domain,
		avoidCache
	});
	return {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	}
}

export const usePostAPI = (props:Props) => {
	const {
		endpoint,
		headers = {},
		domain = 'EOS',
		avoidCache = false
	} = props;
	const {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	} = useAPI({
		endpoint,
		method: 'POST',
		headers: { ...headers },
		domain,
		avoidCache
	});
	return {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	}
}

export const usePatchAPI = (props:Props) => {
	const {
		endpoint,
		headers = {},
		domain = 'EOS',
		avoidCache = false
	} = props;
	const {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	} = useAPI({
		endpoint,
		method: 'PATCH',
		headers: { ...headers },
		domain,
		avoidCache
	});
	return {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	}
}

export const useDeleteAPI = (props:Props) => {
	const {
		endpoint,
		headers = {},
		domain = 'EOS',
		avoidCache = false
	} = props;
	const {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	} = useAPI({
		endpoint,
		method: 'DELETE',
		headers: { ...headers },
		domain,
		avoidCache
	});
	return {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	}
}

export default useAPI;
