import React, {
	useState, useEffect, useRef, useMemo, useCallback
} from 'react';
import styles from './Table.module.scss';
import {
	getCoreRowModel,
	getSortedRowModel,
	getGroupedRowModel,
	useReactTable,
	flexRender,
	getExpandedRowModel,
	RowData,
	getFilteredRowModel,
	TableOptions
} from '@tanstack/react-table';
import { DATA_UPDATE_MODE, DATA_SORT_ORDER } from '@types';
import { useTableState } from '@hooks';
import { Loader, Text, Modal } from '@components';
import Header from './Components/Header';
import Footer from './Components/Footer';
import SettingsPanel from './Components/SettingsPanel';
import MatrixFilterPanel from './Components/MatrixFilterPanel';
import FilterPanel from './Components/FilterPanel';
import FilterModalSave from './Components/FilterModalSave';
import UpArrowIcon from '@mui/icons-material/ExpandLessOutlined';
import DownArrowIcon from '@mui/icons-material/ExpandMoreOutlined';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { v4 as uuid } from 'uuid';
import FilterQueryBuilder from './Utils/FilterQueryBuilder';
import GenerateURL from './Utils/GenerateURL';
import { useWindowSize } from '@hooks';
import { debounce } from 'lodash';
import { localFilter } from './Utils/LocalFilterFns';
import { useSearchParams } from 'react-router-dom';
import { createPortal } from 'react-dom';
import DownloadCsv, { DownloadCsvProps } from './Components/DownloadCsv';

type TFilterOptions = {
	type?: string,
	allowPlaceHolder?: string,
	options?: string|{ value: string; label: string }[],
}
declare module '@tanstack/table-core' {
    interface ColumnMeta<TData extends RowData, TValue> { // eslint-disable-line
		canClick?: boolean;
		canFilter?: boolean;
		canSort?: boolean;
		dataType?: 'data' | 'display';
		databaseLocation?: string;
		className?: any;
		pinLeft?: boolean;
		pinRight?: boolean;
		filterOptions?: TFilterOptions;
	}
}

type TableProps = {
    tableID: string;
    data: any[];
    columns: any[];
    dataUpdateMode?: DATA_UPDATE_MODE;
    canResize?: boolean;
    canSort?: boolean;
    canPin?: boolean;
    canReorder?: boolean;
    canHideColumns?: boolean;
    canFilter?: boolean;
    canSearch?: boolean;
    loading?: boolean;
    pagination?: boolean;
    Components?: {
        [name:string]: (props:any) => any;
    } & {
		Expanded?: (props: any) => React.ReactElement<'tr'>;
	};
    sortingOrder?: DATA_SORT_ORDER[];
    showRowCount?: boolean;
    noDataText?: any;
    tableTitle?: string | JSX.Element | null;
    showHeader?: boolean;
    showFooter?: boolean;
    canChangeRowDensity?: boolean;
    canChangeDisplaySettings?: boolean;
    searchPlaceholder?: string | null;
    globalMinColumnWidth?: number;
    totalRows?: number;
    currentPage?: number;
    rowsPerPage?: number;
    rowsPerPageOpts?: number[];
    onPageChange?: (n:number) => void;
    onRowsPerPageChange?: (n:number) => void;
    onSortChange?: (a:any[]) => void;
    onCellClick?: (cell:any) => void;
    savedFilters?: any[];
    onSaveFilter?: (method:'save' | 'update', filters:any) => void;
    onDeleteFilter?: (id:string) => void;
    onApplyFilter?: (filter:any) => void;
    rowsCanExpand?: boolean;
    rowID?: string;
    onSearch?: (v:string) => void;
    enableRowSelection?: TableOptions<any>['enableRowSelection'];
    rowSelection?: any;
    onRowSelectionChange?: (selected:any[], newChangeState:any, currentSelection:any) => void;
    tableHeight?: any;
    tableHeightPadding?: number;
    onChangeURL?: (url:string) => void;
    continuousPagination?: boolean;
    canChangeRowCount?:boolean;
    externalPanelOpen?: boolean;
    panelOpened?: () => void;
    unique?: boolean;
    triggerClearSearch?: boolean;
    resetTriggerClearSearch?: () => void;
    stickyHeader?: boolean;
	grouping?: string[];
	expandedByDefault?: boolean;
	expanderIcons?: {
		expand?: JSX.Element;
		collapse?: JSX.Element;
	}
	detachPanels?: boolean;
    opsMatrixFilters?: any[];
    noDataComponent?: React.ReactElement;
	customClass?: boolean;
    buttonComponents?: any;
	fullWidthSearch?: boolean;
	filterFromSearchOn?: string[];
	onSetColumnsWidth?: (val: number[]) => void;
	downloadCsvConfig?: DownloadCsvProps['downloadCsvConfig'];
	filterFromParent?: {key: string, value: string|null}
}

export type PanelState = {
    isOpen: boolean,
    activePanel: 'filter' | 'settings' | 'matrix' | null
};

export const Table = (props:TableProps) => {
	const {
		tableID,
		data,
		columns,
		dataUpdateMode = DATA_UPDATE_MODE.SERVER,
		canResize = true,
		canSort = true,
		canPin = true,
		canReorder = true,
		canHideColumns = true,
		canFilter = true,
		canSearch = true,
		loading = false,
		pagination = true,
		Components = null,
		sortingOrder,
		showRowCount = true,
		canChangeRowCount= true,
		stickyHeader= true,
		noDataText = 'No Data Available',
		tableTitle,
		showHeader = true,
		showFooter = true,
		canChangeRowDensity = true,
		canChangeDisplaySettings = true,
		searchPlaceholder = null,
		globalMinColumnWidth = 150,
		totalRows,
		currentPage,
		rowsPerPage,
		rowsPerPageOpts,
		onPageChange,
		onRowsPerPageChange,
		onSortChange,
		onCellClick,
		savedFilters = [],
		onSaveFilter,
		onDeleteFilter,
		onApplyFilter,
		rowsCanExpand = true,
		rowID = null,
		onSearch,
		enableRowSelection = false,
		rowSelection,
		onRowSelectionChange,
		tableHeight,
		tableHeightPadding,
		continuousPagination = false,
		externalPanelOpen = false,
		panelOpened,
		unique = false,
		triggerClearSearch = false,
		resetTriggerClearSearch,
		grouping = [],
		expandedByDefault,
		expanderIcons = {
			expand: <AddIcon />,
			collapse: <RemoveIcon />
		},
		detachPanels = true,
		opsMatrixFilters = [],
		onChangeURL,
		noDataComponent,
		customClass,
		buttonComponents,
		fullWidthSearch,
		filterFromSearchOn,
		onSetColumnsWidth,
		downloadCsvConfig: csvDownloadConfig,
		filterFromParent
	} = props;
	const {
		clearTableState,
		setSortModel,
		sortModel,
		columnVisibility,
		setColumnVisibility,
		pinColumns,
		setColumnPins,
		tableFilters,
		setTableFilters,
		tableColumnOrder,
		setColumnOrder,
		colWidths,
		setColWidths,
		rowDensity,
		setRowDensity,
		loaded,
		saveActiveFilter,
		clearActiveFilter
	} = useTableState(tableID);

	const internalOnRowSelectionChange = (change:any) => {
		if (onRowSelectionChange) {
			const newChangeState = change(rowSelection);
			const newChangeStateKeys = Object.keys(newChangeState);
			const rowModel = table.getRowModel().flatRows.filter((row, index) => { //eslint-disable-line
				return rowID ? newChangeStateKeys.includes(`${row.original[rowID]}`) : newChangeStateKeys.includes(`${index}`);
			}).map((row) => {
				return row.original;
			});
			onRowSelectionChange(rowModel, newChangeState, change());
		}
	}
	const [globalFilter, setGlobalFilter] = useState('');
	const isObject = (o:any):boolean => {
		return typeof o === 'object' && !Array.isArray(o) && o !== null;
	}
	const itemSearcher = (item:any[], value:string):boolean => {
		let hasMatch = false;
		for (let i=0, l=item.length; i<l; ++i) {
			if (typeof item[i] === 'string' && item[i].toLowerCase().includes(value.toLowerCase())) hasMatch = true;
			if (typeof item[i] === 'number' && `${item[i]}`.includes(value)) hasMatch = true;
			if (Array.isArray(item[i]) && item[i].length > 0) {
				const search = itemSearcher(item[i], value) as boolean;
				if (search) hasMatch = true;
			}
			if (isObject(item[i])) {
				const nextData = Object.values(item[i]);
				const search = itemSearcher(nextData, value) as boolean;
				if (search) hasMatch = true;
			}
		}
		return hasMatch;
	}
	const globalFilterFn:any = (row:any, _:string, value:string) => {
		const data:any[] = Object.values(row.original);
		return itemSearcher(data, value);
	}
	const table = useReactTable({
		data,
		columns: columns,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		manualFiltering: dataUpdateMode === DATA_UPDATE_MODE.SERVER,
		manualPagination: dataUpdateMode === DATA_UPDATE_MODE.SERVER,
		manualSorting: dataUpdateMode === DATA_UPDATE_MODE.SERVER,
		enableSorting: canSort,
		sortDescFirst: sortingOrder !== undefined && sortingOrder[0] === DATA_SORT_ORDER.DESC,
		enableColumnResizing: canResize,
		enablePinning: canPin,
		enableHiding: canHideColumns,
		enableFilters: canFilter,
		enableExpanding: true,
		state: {
			columnVisibility,
			columnPinning: pinColumns,
			columnFilters: tableFilters,
			sorting: sortModel,
			columnOrder: tableColumnOrder,
			columnSizing: colWidths,
			rowSelection,
			globalFilter,
			grouping: useMemo(() => grouping, [])
		},
		filterFns: {
			fuzzy: globalFilterFn,
			LocalFilter: localFilter
		},
		onColumnVisibilityChange: setColumnVisibility,
		onColumnPinningChange: setColumnPins,
		onColumnFiltersChange: setTableFilters,
		onGlobalFilterChange: setGlobalFilter,
		globalFilterFn: globalFilterFn,
		onSortingChange: setSortModel,
		onColumnSizingChange: setColWidths,
		columnResizeMode: 'onChange',
		getRowCanExpand: rowsCanExpand ? () => true : () => false,
		getExpandedRowModel: getExpandedRowModel(),
		getGroupedRowModel: getGroupedRowModel(),
		enableRowSelection: enableRowSelection,
		onRowSelectionChange: internalOnRowSelectionChange,
		getRowId: (originalRow, index) => {
			return rowID ? `${originalRow[rowID]}` : `${index}`;
		},
		getFilteredRowModel: getFilteredRowModel()
	});

	const [, winHeight] = useWindowSize();
	const [isLoading, setIsLoading] = useState(loading);
	const [panelState, setPanelState] = useState<PanelState>({
		isOpen: false,
		activePanel: null
	});
	const [panelOpen, setPanelOpen] = useState(false);
	const [, setFilterPanel] = useState(false);
	const [, setSettingsPanel] = useState(false);
	const [loadedFilter, setLoadedFilter] = useState<any>(null);
	const [showSaveFilter, setShowSaveFilter] = useState(false);
	const [pendingSaveFilter, setPendingSaveFilter] = useState<any>(null);
	const [searchFromParent, setSearchFromParent] = useState('');
	const [containerHeight, setContainerHeight] = useState<null | string>(null);
	const concatLength = useRef(0);
	const containerRef = useRef<any>(null);
	const tableContainerRef = useRef<any>(null);
	const hasStretchCol = useRef<boolean>(false);
	const [searchParams] = useSearchParams();
	const isSort = searchParams.get('sort');

	const resetClearState = () => {
		clearTableState({ exceptions: { visible: true } });
	}

	const renderSubComponent = (row:any) => {
		if (Components?.Expanded) {
			return <Components.Expanded row={row} />;
		}
		return <></>
	}

	const renderHeaderTopSubComponent = () => {
		if (Components?.['HeaderTop']) {
			return <Components.HeaderTop />
		}
	}

	const getSize = (header:any, i:number, l:number, headerGroup: any) => {
		let customHeaderWidth = 0;
		if (i + 1 < l) {
			const totalCols = columns.filter((col) => {
				const dataType = col.meta?.dataType;
				if (col.meta && col.meta?.headerSize !== undefined) {
					customHeaderWidth = col.meta.headerSize;
				}
				return dataType === undefined || dataType === 'display';
			}).length;
			const totalColWidth = headerGroup.headers.reduce((acc: number, curr: any) => {
				return acc += curr.getSize();
			}, 0);
			const hasCustomWidths = colWidths && Object.keys(colWidths).length > 0;
			let len = header.getSize();
			let tableWidth = 0;
			if (tableContainerRef.current) {
				tableWidth = tableContainerRef.current.offsetWidth;
			}
			if (header?.column?.columnDef?.meta?.stretch) {
				hasStretchCol.current = true;
				return tableWidth <= totalColWidth ? header.getSize() : undefined;
			}
			if (tableWidth > 0 && !hasStretchCol.current && !hasCustomWidths && customHeaderWidth === 0) {
				const tempWidth = Math.floor(tableWidth / totalCols);
				const forceWidth = header?.column?.columnDef?.meta?.forceWidth;
				if (tempWidth > len && !forceWidth) {
					len = tempWidth;
				}
			}
			if (tableContainerRef.current) {
				concatLength.current = concatLength.current + len;
			}
			return len;
		}
		if (tableContainerRef.current) {
			const findRemainingWidth = tableContainerRef.current.offsetWidth - concatLength.current
			concatLength.current = 0;
			const forceWidth = header?.column?.columnDef?.meta?.forceWidth;
			if (forceWidth) {
				return header.getSize();
			}
			return findRemainingWidth > header.getSize() ? findRemainingWidth : header.getSize() < globalMinColumnWidth ? globalMinColumnWidth : header.getSize();
		}
	}
	const getPos = (header:any, i:number, headers:any) => {
		const styles:any = {};
		if (header.column.getIsPinned() === 'right') {
			let size = 0;
			for (let dex=headers.length - 1; i < dex; --dex) {
				if (headers[dex] !== undefined) {
					if (dex === headers.length - 1) {
						size = size + (headers[dex].column.getSize() < globalMinColumnWidth ? globalMinColumnWidth : headers[dex].column.getSize());
					} else {
						size = size + headers[dex].column.getSize();
					}
				}
			}
			styles.right = `${size}px`;
		}
		if (header.column.getIsPinned() === 'left') {
			let size = 0;
			for (let dex=0; dex < i; ++dex) {
				if (headers[dex] !== undefined) {
					size = size + headers[dex].column.getSize();
				}
			}
			styles.left = `${size}px`;
		}
		return styles;
	}
	const canClick = (meta:any) => {
		if (meta?.canClick === undefined || meta?.canClick) {
			return true;
		}
		return false;
	}
	const generateURL = (props:any) => {
		const newProps = {
			unique,
			tableID,
			dataUpdateMode,
			currentPage,
			rowsPerPage,
			sortModel,
			searchFromParent,
			loadedFilter: loadedFilter && !loadedFilter.type ? FilterQueryBuilder(loadedFilter) : null,
			matrixId: loadedFilter && loadedFilter.type === 'matrix' ? loadedFilter.id : null,
			...props
		}
		const URL:string = GenerateURL(newProps);
		if (onChangeURL) {
			onChangeURL(URL);
		}
	}

	const setPageNumber = (n: number) => {
		if (onPageChange) onPageChange(n);
		generateURL({ currentPage: n });
	}
	const closePanel = () => {
		setPanelState({ ...panelState, isOpen: false });
	};
	const preOnApplyFilter = (filter:any) => {
		let page = currentPage;
		if (filter) {
			if (tableFilters.length === 0) {
				page = 1;
				setPageNumber(1);
			}
			saveActiveFilter(tableID, filter?.id)
		} else {
			clearActiveFilter(tableID)
		}
		setLoadedFilter(filter);
		closePanel();
		const query = filter === null ? null : FilterQueryBuilder(filter);
		if (onApplyFilter) onApplyFilter(query);
		generateURL({
			loadedFilter: query, matrixId: null, currentPage: page
		});
	}
	const triggerSaveFilter = (filter:any) => {
		if (filter.id === undefined) {
			setShowSaveFilter(true);
			setPendingSaveFilter(filter);
		} else {
			const newFilters:any[] = [...tableFilters];
			const filterIndex = newFilters.findIndex((thisFilter) => {
				return thisFilter.id === filter.id;
			});
			if (filterIndex >= 0) {
				newFilters[filterIndex] = filter;
			}
			setTableFilters(newFilters);
			if (onSaveFilter) onSaveFilter('update', filter);
			preOnApplyFilter(filter);
		}
	}
	const realSaveFilter = (filterName:string) => {
		const newFilter:any = { ...pendingSaveFilter };
		const newFilters:any = [...tableFilters];
		newFilter.id = uuid();
		newFilter.filterName = filterName;
		newFilters.push(newFilter);
		setTableFilters(newFilters);
		if (onSaveFilter) onSaveFilter('save', newFilter);
		preOnApplyFilter(newFilter);
	}
	const updateLoadedFilter = (id:string | null) => {
		if (id === null) {
			preOnApplyFilter(null);
			return;
		}
		const getFilter = tableFilters.filter((filter:any) => {
			return filter.id === id;
		});
		if (getFilter.length > 0) {
			setLoadedFilter(getFilter[0]);
			preOnApplyFilter(getFilter[0]);
		}
	}
	const [activeFilterLoading, setActiveFilterLoading] = useState<boolean>(false);
	const setActiveFilter = (tableID: string) => {
		const key = tableID ?? window.location.pathname
		const filterId = localStorage.getItem(`@${key}-active-filter`)
		if (filterId && !activeFilterLoading && !window.location.search.includes(filterId)) {
			setActiveFilterLoading(true)
			updateLoadedFilter(filterId)
		}
	}
	const initState = () => {
		const colVis:any = { ...columnVisibility };
		const colPins:any = { ...pinColumns };
		for (let i=0, l=columns.length; i<l; ++i) {
			if (columns[i].meta && columns[i].meta.pinLeft) {
				if (!colPins.left.includes(columns[i].accessorKey)) colPins.left.push(columns[i].accessorKey);
			}
			if (columns[i].meta && columns[i].meta.pinRight) {
				if (!colPins.right.includes(columns[i].accessorKey)) colPins.right.push(columns[i].accessorKey);
			}
			if (
				(columns[i].meta &&
                columns[i].meta.dataType &&
                columns[i].meta.dataType === 'data' &&
                colVis[columns[i].accessorKey] === undefined) ||
                (columns[i].meta &&
                columns[i].meta.hide)
			) {
				colVis[columns[i].accessorKey] = false
			}
		}
		setColumnVisibility(colVis);
		setColumnPins(colPins);
		setActiveFilter(tableID)
	}

	const applyMatrixFilter = (filter:any) => {
		setLoadedFilter(filter);
	}

	const deleteFilter = (id:string) => {
		const cloneFilters = [...tableFilters];
		const newFilters = cloneFilters.filter((filter:any) => {
			return filter.id !== id;
		});
		setLoadedFilter(null);
		setTableFilters(newFilters);
		if (onDeleteFilter) onDeleteFilter(id);
		if (onApplyFilter) onApplyFilter(null);
	}

	const sortColumn = (header:any) => {
		const sortDir = header.column.getIsSorted();
		let copy:any[] = [...sortModel];
		if (sortDir === 'asc') {
			const sortIndex = copy.findIndex((s) => {
				return s.id === (header?.column?.id ?? '')
			});
			copy[sortIndex].desc = true;
		} else if (sortDir === 'desc') {
			copy = copy.filter((s) => {
				return s.id !== (header?.column?.id ?? '');
			});
		} else {
			copy.push({
				_id: new Date().getTime(),
				id: header?.column?.id ?? '',
				desc: false,
				label: header?.column?.columnDef?.header,
				value: header?.column?.columnDef?.meta?.databaseLocation ?? header?.column?.columnDef?.accessorKey
			});
		}
		const lastObject = copy[copy.length - 1]
		const copyvalue = lastObject ? [lastObject] : []
		setSortModel(copyvalue);
		if (onSortChange) onSortChange(copyvalue);
		generateURL({ sortModel: copyvalue });
	}
	const localSearch = useCallback(
		debounce((search) => {
			setGlobalFilter(search);
		}, 500),
		[]
	);
	const triggerSearch = (search:string) => {
		onSearch?.(search);
		generateURL({ searchFromParent: search, currentPage: 1 });
		if (dataUpdateMode === DATA_UPDATE_MODE.LOCAL) {
			localSearch(search);
		}
	}
	const filterFromSearch = (filter:any) => {
		const query = filter === null ? null : FilterQueryBuilder(filter, 'or');
		generateURL({ loadedFilter: query });
	}
	const setRowCount = (n:number) => {
		if (onRowsPerPageChange) onRowsPerPageChange(n);
		generateURL({ currentPage: 1, rowsPerPage: n });
	}


	const openPanel = (panelName: PanelState['activePanel']) => {
		setPanelState({ isOpen: true, activePanel: panelName });
	};

	const generateFilterPanel = () => {
		return <FilterPanel
			setPanelOpen={setPanelOpen}
			setFilterPanel={setFilterPanel}
			fromTableColumns={columns}
			saveFilters={triggerSaveFilter}
			applyFilter={loadedFilter}
			clearAppliedFilter={updateLoadedFilter}
			deleteFilter={deleteFilter}
			onApplyFilter={preOnApplyFilter}
			panelState={panelState}
			openPanel={openPanel}
			closePanel={closePanel}
		/>
	}
	const generateSettingsPanel = () => {
		return <SettingsPanel
			setPanelOpen={setPanelOpen}
			setSettingsPanel={setSettingsPanel}
			rowDensity={rowDensity}
			setRowDensity={setRowDensity}
			columns={columns}
			columnOrder={tableColumnOrder}
			setColSort={(cols) => { setColumnOrder(cols) }}
			columnVisibility={columnVisibility}
			setColumnVisibility={setColumnVisibility}
			pinColumns={pinColumns}
			setPinColumns={setColumnPins}
			fromTableColumns={table.getFlatHeaders()}
			setSortModel={(sortArr) => {
				setSortModel(sortArr);
				if (onSortChange) onSortChange(sortArr);
			}}
			generateURLs={generateURL}
			sortModel={sortModel}
			clearTableState={resetClearState}
			canChangeRowDensity={canChangeRowDensity}
			canSort={canSort}
			canReOrderCols={canReorder}
			canPin={canPin}
			canHideCols={canHideColumns}
			panelState={panelState}
			openPanel={openPanel}
			closePanel={closePanel}
		/>
	}

	const generateMatrixFilterPanel = () => {
		return <MatrixFilterPanel
			panelState={panelState}
			openPanel={openPanel}
			closePanel={closePanel}
			matrix={loadedFilter}
		/>
	}

	const renderPanel = () => {
		switch (panelState.activePanel) {
		case 'filter':
			return generateFilterPanel();
		case 'settings':
			return generateSettingsPanel();
		case 'matrix':
			return generateMatrixFilterPanel();
		default:
			return null;
		}
	}

	const triggerLocationSearch = () => {
		const urlParams = new URLSearchParams(window.location.search);
		const filterSearchParam = urlParams.get('filter[search]');
		const nonFilterSearchParam = urlParams.get('search');
		let searchParam = null;
		if (filterSearchParam) {
			searchParam = filterSearchParam;
		} else if (nonFilterSearchParam) {
			searchParam = nonFilterSearchParam;
		}
		const filterParam = urlParams.get('filterID');
		const sortParam = urlParams.get('sort');
		const tableId = urlParams.get('tableId');
		if (!tableId || (tableId && tableId === tableID)) {
			if (sortParam === null && sortModel && sortModel.length) {
				if (onSortChange) onSortChange(sortModel);
			} else if (sortParam !== null) {
				let sortingVal = sortParam;
				const isDesc = sortParam.charAt(0) === '-';
				if (isDesc) {
					sortingVal = sortParam.slice(1);
				}
				const cols = table.getFlatHeaders().filter((col:any) => col.column.getCanSort()).map((col:any) => {
					const colDef = col.column.columnDef;
					return {
						id: col.id,
						label: colDef.header,
						value: colDef?.meta?.databaseLocation ?? colDef.accessorKey
					}
				}).filter((c) => {
					return c.value === sortingVal
				});
				const newSortArr = [
					{
						...cols[0],
						_id: new Date(),
						desc: isDesc
					}
				];
				setSortModel(newSortArr);
			}
			if (searchParam !== null) {
				setSearchFromParent(searchParam);
				if (dataUpdateMode === DATA_UPDATE_MODE.LOCAL) {
					localSearch(searchParam);
				}
			} else {
				setSearchFromParent('');
			}
			if (filterParam !== null && loadedFilter === null) {
				updateLoadedFilter(filterParam);
			}
		}
	}

	useEffect(() => {
		if (table?.getAllColumns().length) {
			onSetColumnsWidth?.(table?.getAllColumns()?.map((col) => col.getSize()));
		}
	}, [table]);

	useEffect(() => {
		setIsLoading(loading);
	}, [loading]);

	useEffect(() => {
		if (loaded) initState();
	}, [columns, loaded]);

	useEffect(() => {
		if (!isSort) localStorage.setItem('@ofloadTableSortOrder', '{}')
	}, [sortModel]);

	useEffect(() => {
		if (savedFilters && savedFilters?.length) {
			setTableFilters(savedFilters);
		}
	}, [savedFilters]);

	useEffect(() => {
		if (loadedFilter !== null) {
			generateURL({ currentPage: 1 });
		}
	}, [loadedFilter]);

	useEffect(() => {
		if (tableHeight && tableHeight === 'window') {
			const newWinHeight = winHeight - 115;
			let newHeight;
			if (tableHeightPadding) {
				newHeight = `${newWinHeight - tableHeightPadding}px`;
			} else {
				newHeight = `${newWinHeight}px`;
			}
			setContainerHeight(newHeight);
		} else if (tableHeight) {
			setContainerHeight(tableHeight);
		}
	}, [tableHeight, winHeight]);

	useEffect(() => {
		if (triggerClearSearch) {
			setSearchFromParent('');
			if (resetTriggerClearSearch) resetTriggerClearSearch();
			if (dataUpdateMode === DATA_UPDATE_MODE.LOCAL) setGlobalFilter('');
		}
	}, [triggerClearSearch]);

	useEffect(() => {
		triggerLocationSearch();
	}, [window.location.search]);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const filterParam = urlParams.get('filterID');
		if (tableFilters.length && filterParam !== null && loadedFilter === null) {
			updateLoadedFilter(filterParam);
		}
	}, [tableFilters]);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const matrixFilterParam = urlParams.get('matrixID');
		if (matrixFilterParam !== null && loadedFilter === null && opsMatrixFilters.length) {
			const getMatrix = opsMatrixFilters.filter((filter:any) => {
				return filter.id === parseInt(matrixFilterParam);
			});
			if (getMatrix.length > 0) {
				setLoadedFilter(getMatrix[0])
			}
		}
	}, [opsMatrixFilters]);

	useEffect(() => {
		const expand = (expandedByDefault === undefined && grouping.length > 0) ? true : expandedByDefault;
		if (expand) {
			setTimeout(() => {
				table.toggleAllRowsExpanded(expand);
			}, 0);
		}
	}, []);

	useEffect(() => {
		if (externalPanelOpen) {
			setPanelOpen(false);
			setFilterPanel(false);
			setSettingsPanel(false);
		}
	}, [externalPanelOpen]);

	useEffect(() => {
		if (filterFromParent?.value) {
			const filter = {
				[filterFromParent.key]: {
					columnField: filterFromParent.key,
					operatorValue: 'Is',
					value: filterFromParent.value
				}
			}
			setLoadedFilter(filter)
		} else {
			setLoadedFilter(null);
			resetClearState()
		}
	}, [filterFromParent?.value, filterFromParent?.key]);

	useEffect(() => {
		if (panelOpen && panelOpened) {
			panelOpened();
		}
	}, [panelOpen]);

	useEffect(() => {
		const trigger = () => {
			if (dataUpdateMode === DATA_UPDATE_MODE.LOCAL) {
				if (loadedFilter !== null) {
					const cols = table.getAllColumns();
					const filters:any[] = Object.values(loadedFilter);
					for (let i=0, l=filters.length; i<l; ++i) {
						const filter:any = filters[i];
						const col:any[] = cols.filter((c) => {
							const colMeta:any = c.columnDef.meta;
							const colID = colMeta.databaseLocation ?? c.id;
							return colID === filter.columnField
						});
						if (col.length > 0) {
							const filterOpts = {
								meta: col[0].columnDef.meta,
								value: filters[i].value,
								updater: col[0].setFilterValue
							};
							col[0].setFilterValue(() => {
								return filterOpts;
							});
						}
					}
				} else {
					table.resetColumnFilters();
				}
			}
		}
		trigger();
	}, [loadedFilter]);

	return (
		<>
			<div className={styles.container} ref={containerRef}>
				{showHeader && <Header
					tableTitle={tableTitle}
					canSearch={canSearch}
					downloadCsvPlugin={
						<DownloadCsv
							urlHaveTableId={unique}
							tableId={tableID}
							downloadCsvConfig={csvDownloadConfig}
						/>}
					/*
					 * setPanelOpen={setPanelOpen}
					 * setFilterPanel={setFilterPanel}
					 * setSettingsPanel={setSettingsPanel}
					 * settingsPanel={settingsPanel}
					 * filterPanel={filterPanel}
					 */
					canChangeDisplaySettings={canChangeDisplaySettings}
					canFilter={canFilter}
					searchPlaceholder={searchPlaceholder}
					tableFilters={tableFilters}
					applyFilter={(id:string | null) => { updateLoadedFilter(id) }}
					applyMatrixFilter={applyMatrixFilter}
					loadedFilter={loadedFilter}
					onSearch={triggerSearch}
					searchFromParent={searchFromParent}
					panelState={panelState}
					openPanel={openPanel}
					closePanel={closePanel}
					opsMatrixFilters={opsMatrixFilters}
					buttonComponents={buttonComponents}
					fullWidthSearch={fullWidthSearch}
					filterFromSearchOn={filterFromSearchOn}
					filterFromSearch={filterFromSearch}
				/>}
				<div className={styles.content}>
					<div
						ref={tableContainerRef}
						className={`
                        ${data.length > 1 && customClass ? styles.customTableContainer : styles.tableContainer}
                            ${data.length < 1 || isLoading ? styles.setTableContainerheight : ''}
                        `}
						style={{ height: containerHeight === null ? 'auto' : containerHeight }}
					>
						{
							renderHeaderTopSubComponent()
						}
						<table cellSpacing={0} cellPadding={0} className={styles.table} {...{ style: { minWidth: '100%', width: table.getCenterTotalSize(), }, }}>
							<thead className={`${stickyHeader ? styles.stickyHeader : ''}`}>
								{table.getHeaderGroups().map((headerGroup) => (
									<tr key={headerGroup.id}>
										{headerGroup.headers.map((header, index) => (
											<th
												key={header.id}
												className={`${header.column.getIsPinned() === 'left' ? styles.pinnedLeft : ''} ${!header.column.getIsPinned() ? styles.noPin : ''} ${header.column.getIsPinned() === 'right' ? styles.pinnedRight : ''}`}
												style={{ width: getSize(header, index, headerGroup.headers.length, headerGroup), ...getPos(header, index, headerGroup.headers) }}
											>
												<div className={styles.cellContents}>
													{header.isPlaceholder
														? null
														: flexRender(
															header.column.columnDef.header,
															header.getContext()
														)}
												</div>
												{canSort && header.column.getCanSort() && <div className={styles.colSorter} onClick={() => { sortColumn(header) }}>
													<UpArrowIcon style={{
														marginBottom: '-5px', fontSize: '20px', color: { asc: 'var(--brand-orange-default)', desc: '#d4d4d4' }[header.column.getIsSorted() as string] ?? '#d4d4d4'
													}} />
													<DownArrowIcon style={{
														marginTop: '-5px', fontSize: '20px', color: { asc: '#d4d4d4', desc: 'var(--brand-orange-default)' }[header.column.getIsSorted() as string] ?? '#d4d4d4'
													}} />
												</div>}
												{canResize && header.column.getCanResize() && <div
													{...{
														onMouseDown: header.getResizeHandler(),
														onTouchStart: header.getResizeHandler(),
														className: `${styles.resizer} ${
															header.column.getIsResizing() ? styles.isResizing : ''
														}`
													}}
												/>}
											</th>
										))}
									</tr>
								))}
							</thead>
							<tbody>
								{isLoading &&
									<tr>
										<td colSpan={table?.getHeaderGroups()[0]?.headers.length} className={`${styles.blankContainer} ${tableHeight !== 'window' && styles.blankContainer_NoPadding}`}>
											<span>
												<Loader />
											</span>
										</td>
									</tr>
								}
								{
									data.length < 1 &&
                                    !isLoading &&
                                    !noDataComponent &&
									<tr>
										<td colSpan={table?.getHeaderGroups()[0]?.headers.length} className={`${styles.blankContainer} ${tableHeight !== 'window' && styles.blankContainer_NoPadding}`}>
											<span>
												<Text text={noDataText} />
											</span>
										</td>
									</tr>
								}
								{
									data.length < 1 &&
                                    !isLoading &&
                                    noDataComponent &&
									<tr>
										<td colSpan={table?.getHeaderGroups()[0]?.headers.length} className={`${styles.blankContainer} ${tableHeight !== 'window' && styles.blankContainer_NoPadding}`}>
											{noDataComponent}
										</td>
									</tr>
								}
								{!isLoading && table.getRowModel().rows.map((row) => {
									return <React.Fragment key={row.id}>
										<tr className={`${styles[rowDensity]}  ${customClass}`} id={rowID ? row.original[rowID] : row.id}>
											{row.getVisibleCells().map((cell, index) => (
												<td
													onClick={() => {
														if (
															canClick(cell?.column?.columnDef?.meta) &&
                                                        onCellClick &&
                                                        !cell.getIsGrouped()
														) {
															onCellClick(cell);
														}
													}}
													key={cell.id}
													data-testid={cell.id}
													className={`${cell.column.getIsPinned() === 'left' ? styles.pinnedLeft : ''} ${!cell.column.getIsPinned() ? styles.noPin : ''} ${cell.column.getIsPinned() === 'right' ? styles.pinnedRight : ''} ${canClick(cell?.column?.columnDef?.meta) ? styles.canClick : ''} ${cell?.column?.columnDef?.meta?.className ? cell?.column?.columnDef?.meta?.className :''}`}
													style={{ width: cell.column.getSize(), ...getPos(cell, index, row.getVisibleCells()) }}
												>
													{cell.getIsGrouped() ? (
														<>
															<button
																{...{
																	onClick: row.getToggleExpandedHandler(),
																	style: { marginRight: '10px' },
																}}
																name={'Toggle Expanded'}
															>
																{row.getIsExpanded() ? expanderIcons.collapse : expanderIcons.expand}
															</button>
															{flexRender(cell.column.columnDef.cell, cell.getContext())} {cell?.row?.subRows?.length > 0 ? `(${cell?.row?.subRows?.length})` : ''}
														</>
													) : cell.getIsAggregated() ? (
														flexRender(
															cell.column.columnDef.aggregatedCell ??
															cell.column.columnDef.cell, cell.getContext())
													) : cell.getIsPlaceholder() ? null : (
														flexRender(cell.column.columnDef.cell, cell.getContext())
													)}
												</td>
											))}
										</tr>
										{row.getIsExpanded() && renderSubComponent(row)}
									</React.Fragment>
								})
								}
							</tbody>
						</table>
					</div>
					{!detachPanels && <div className={`${styles.slidePanel} ${panelState.isOpen ? styles.panelOpen : styles.panelClosed}`}>
						{renderPanel()}
					</div>}
				</div>
				{showFooter && <Footer
					pagination={pagination}
					showRowCount={showRowCount}
					canChangeRowCount={canChangeRowCount}
					totalDocs={totalRows || data.length}
					currentPage={currentPage}
					rowsPerPage={rowsPerPage}
					setPageNumber={(n) => {
						setPageNumber(n);
					}}
					setRowCount={(n) => {
						setRowCount(n);
					}}
					rowsPerPageOpts={rowsPerPageOpts}
					continuousPagination={continuousPagination}
				/>}
			</div>
			<Modal
				open={showSaveFilter}
				toggleOpen={setShowSaveFilter}
				closeOnEscape={true}
				showCloseButton={false}
			>
				<FilterModalSave
					saveFilter={realSaveFilter}
					cancel={() => { setShowSaveFilter(false) }}
				/>
			</Modal>
			{detachPanels && createPortal(<div className={`${styles.slidePanel} ${styles.slidePanelDetached} ${panelState.isOpen ? styles.panelOpen : styles.panelClosed}`}>
				{renderPanel()}
			</div>, document.body)}
		</>
	);
}
