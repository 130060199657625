import { useEffect } from 'react';
import styles from './TopNavigation.module.scss';
import {
	Text, Link, OfloadIcon, Spacer, Loader, ShipperCostCenterSelector
} from '@components';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ComputerRoundedIcon from '@mui/icons-material/ComputerRounded';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import TransitEnterexitIcon from '@mui/icons-material/TransitEnterexit';
import LockResetIcon from '@mui/icons-material/LockReset';
// import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SecurityIcon from '@mui/icons-material/Security';
import LogoutIcon from '@mui/icons-material/Logout';
import CloseIcon from '@mui/icons-material/Close';
import { ShipperIcon, TransportIcon } from '@img/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { SPACER_DIRECTION, TEXT_WEIGHT, navigator } from '@types';
import { clearPendoIdentify, clearCookies } from '@utils';
import { useLogout } from '@api';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import {
	UserName,
	IsAdmin,
	SideNavOpen,
	UserMenuOpen,
	JWT,
	UserRoles
	// CID,

	// SID
} from '@state';

export const TopNavigation = () => {
	const setJWT = useSetRecoilState(JWT);
	const userName = useRecoilValue(UserName);
	const isAdmin = useRecoilValue(IsAdmin);
	const userRoles = useRecoilValue(UserRoles);
	// const sID = useRecoilValue(SID);
	/*
	 * const cID = useRecoilValue(CID);
	 * const sID = useRecoilValue(SID);
	 */
	const [sideNavOpen, setSideNavOpen] = useRecoilState(SideNavOpen);
	const [userMenuOpen, setUserMenuOpen] = useRecoilState(UserMenuOpen);
	const location = useLocation();
	const navigate = useNavigate();
	const {
		isLoadingLogout,
		logoutData,
		logoutError,
		callLogoutAPI
	} = useLogout();

	const triggerLogout = () => {
		callLogoutAPI();
	}
	const goToURL = (url: string, newWindow: boolean = false) => {
		// navigate('/forgot'); // --> FOR SPA!!
		if (newWindow) {
			window.open(url);
		} else {
			window.location.href = url;
		}
	}
	const isActive = (label:string) => {
		const parts = location.pathname.split('/');
		return parts[1].toLocaleLowerCase() === label.toLowerCase();
	}
	const navItems:navigator[] = [
		{
			label: 'Inbox',
			icon: (color:string) => <TransitEnterexitIcon className={styles.icons} style={{ transform: 'rotate(270deg)', color: color }} />,
			link: 'inbox',
			domain: 'spa'
		},
		{
			label: 'Shipper',
			icon: (color:string) => <ShipperIcon width={22} height={22} color={color} />,
			// link: (sID === null) ? 'shipper/dashboard' : `shipper/${sID}/dashboard`, // --> SPA
			link: '/admin/shipper/overview',
			domain: 'eos'
		},
		{
			label: 'Carrier',
			icon: (color:string) => <LocalShippingOutlinedIcon className={styles.icons} style={{ color: color }} />,
			// link: (cID === '' || cID === null) ? 'carrier/dashboard' : `carrier/${cID}/dashboard`, // --> SPA
			link: '/admin/carrier/overview',
			domain: 'eos'
		},
		{
			label: 'Fulfilment',
			icon: (color:string) => <ComputerRoundedIcon className={styles.icons} style={{ color: color }} />,
			link: 'fulfilment',
			domain: 'spa'
		},
		{
			label: 'Transport',
			icon: (color:string) => <TransportIcon width={22} height={22} color={color} />,
			// link: 'transport', // --> SPA
			link: '/admin/transport/transport-shipment/all-loads',
			domain: 'eos'
		},
		{
			label: 'Finance',
			icon: (color:string) => <MonetizationOnOutlinedIcon className={styles.icons} style={{ color: color }} />,
			// link: 'finance/billing/shipper', // --> SPA
			link: 'finance/shipper_billing',
			domain: 'spa'
		},
		{
			label: 'Configuration',
			icon: (color:string) => <SettingsOutlinedIcon className={styles.icons} style={{ color: color }} />,
			// link: 'configuration/dashboard', // --> SPA
			link: '/configuration/dashboard',
			domain: 'eos'
		}
	];

	const filteredNavItems = userRoles.includes('mf_freight_user')
		? navItems.filter(
			(item) => item.label !== 'Finance' && item.label !== 'Configuration' && item.label !== 'Inbox')
		: navItems;

	const runLogoutCleanup = async () => {
		// @ts-ignore
		if (window.pendo) {
			clearPendoIdentify();
		}
		await sessionStorage.clear();
		await localStorage.removeItem('jwt');
		await localStorage.removeItem('refreshToken');
		await clearCookies();
		setUserMenuOpen(false);
		setJWT(null);
		navigate('/');
	}

	useEffect(() => {
		if (logoutData) {
			runLogoutCleanup();
		}
	}, [logoutData]); // eslint-disable-line

	useEffect(() => {
		if (logoutError) {
			// Force logout jwt if any error
			runLogoutCleanup();
		}
	}, [logoutError]);

	return (
		<div data-testid={'@Ofload-TopNavigation'} className={`${styles.container} ${sideNavOpen ? styles.menuPadding : ''}`}>
			{!sideNavOpen && <button data-testid={'@Ofload-TopNav-ToggleBtn'} className={styles.menuButton} onClick={() => { setSideNavOpen(true) }} aria-label={'Toggle Side Nav'}>
				<MenuIcon />
			</button>}
			{isAdmin && <nav className={styles.menuItems}>
				{filteredNavItems.map((item, i) => {
					const color = isActive(item.label) ? 'var(--brand-orange-default)' : 'var(--neutral-07)';
					return <Link domain={item.domain || 'spa'} to={item.link} key={i} className={`${styles.menuItem} ${isActive(item.label) ? styles.activeItem : ''}`}>{item.icon(color)}<Text text={item.label} weight={isActive(item.label) ? TEXT_WEIGHT.BOLD : TEXT_WEIGHT.REGULAR} color={color} /></Link>
				})}
			</nav>}
			{!isAdmin && isActive('Shipper') && <ShipperCostCenterSelector />}
			<div data-testid={'@Ofload-TopNav-UserMenu'} className={styles.user} onClick={() => { setUserMenuOpen(!userMenuOpen) }}>
				<Text text={userName ?? 'Ofload'} />
				<ArrowDownIcon />
			</div>
			<div className={`${styles.userMenu2} ${userMenuOpen ? styles.openMenu : ''}`}>
				<button className={styles.closeBtn} onClick={() => { setUserMenuOpen(false) }} aria-label={'Toggle User Menu'}><CloseIcon style={{ color: 'var(--brand-orange-default)' }} /></button>
				<div className={styles.userTile}>
					<div className={styles.logo}>
						<OfloadIcon color={'var(--brand-navy-default)'} accent={'var(--brand-orange-default)'} size={50} />
					</div>
					<Spacer size={10} />
					<Text type={'h5'} text={'Ofload'} />
				</div>
				<Spacer size={40} />
				<button onClick={() => goToURL('/reset')} aria-label={'Reset Password'}>
					<Text>
						<LockResetIcon /> Reset Password
					</Text>
				</button>
				{/* <button onClick={() => {}} aria-label={'Help'}>
					<Text>
						<HelpOutlineIcon /> Help
					</Text>
				</button> */}
				<button onClick={() => goToURL('https://www.ofload.com.au/privacy-policy?_gl=1*1cbde4n*_ga*Mzg1NDU1ODI1LjE3MjI5MDU3NTQ.*_ga_5GPTS2PNJT*MTcyMjkxMTUwOC4yLjEuMTcyMjkxMTc2NC41NC4wLjA.', true)} aria-label={'Privacy Policy'}>
					<Text>
						<SecurityIcon /> Privacy Policy
					</Text>
				</button>
				<button onClick={triggerLogout} aria-label={'Logout'}>
					<Text>
						{!isLoadingLogout && <LogoutIcon />}
						{isLoadingLogout && <>
							<Spacer size={2} dir={SPACER_DIRECTION.VERTICAL} />
							<Loader size={20} thickness={3} />
							<Spacer size={12} dir={SPACER_DIRECTION.VERTICAL} />
						</>}
						Logout
					</Text>
				</button>
			</div>
		</div>
	);
}
