import { useEffect, useState, useCallback, ChangeEvent } from 'react';
import { TypeAhead } from '@components';
import { debounce } from 'lodash';
import { useGetAutocomplete } from '@api';
import { TypeAheadProps } from '@components/FORM/TypeAhead/TypeAhead';
import { AJAXError, TGroupedOpts, TypeAheadOption } from '@types';
import { AxiosError } from 'axios';

interface TProps extends TypeAheadProps {
	locationType?: 'REGION' | 'COMPLETE_ADDRESS' | 'POSTCODE',
	onFetchError?: (e:Error | AxiosError | AJAXError | null) => void;
}

export const AddressPicker = (props:TProps) => {
	const {
		name,
		locationType,
		id,
		value,
		onChange,
		onSelected,
		onFetchError,
		options: extOpts = null,
		...rest
	} = props;
	const {
		loadingAutocomplete,
		addresses,
		getAutocompleteError,
		callGetAutocomplete
	} = useGetAutocomplete();
	const [options, setOptions] = useState<TGroupedOpts | null>(null);
	const search = useCallback( // eslint-disable-line
		debounce((search:string) => {
			callGetAutocomplete(search, locationType);
		}, 500),
		[]);
	const updateValue = (e:ChangeEvent<HTMLInputElement>) => {
		onChange(e);
		if (e) search(e.target.value);
	}
	const updateSelected = (v:TypeAheadOption | string, p?:{ group: string }) => {
		const newProps = p;
		if (newProps && newProps.group) {
			if (newProps.group === 'Addresses') newProps.group = 'COMPLETE_ADDRESS';
			if (newProps.group === 'Postcodes') newProps.group = 'POSTCODE';
			if (newProps.group === 'Regions') newProps.group = 'REGION';
		}
		onSelected(v, newProps);
	}

	useEffect(() => {
		if (addresses && addresses.data) {
			const { suggestions } = addresses.data;
			const newOpts:TGroupedOpts = {}
			if ((suggestions.COMPLETE_ADDRESS && suggestions.COMPLETE_ADDRESS.length && !locationType) || (suggestions.COMPLETE_ADDRESS && suggestions.COMPLETE_ADDRESS.length && locationType === 'COMPLETE_ADDRESS')) newOpts['Addresses'] = suggestions.COMPLETE_ADDRESS;
			if ((suggestions.POSTCODE && suggestions.POSTCODE.length && !locationType) || (suggestions.POSTCODE && suggestions.POSTCODE.length && locationType === 'POSTCODE')) newOpts['Postcodes'] = suggestions.POSTCODE;
			if ((suggestions.REGION && suggestions.REGION.length && !locationType) || (suggestions.REGION && suggestions.REGION.length && locationType === 'REGION')) newOpts['Regions'] = suggestions.REGION;
			setOptions(newOpts);
		}
	}, [addresses]);
	useEffect(() => {
		if (getAutocompleteError) {
			if (onFetchError) onFetchError(getAutocompleteError)
		}
	}, [getAutocompleteError]) // eslint-disable-line
	useEffect(() => {
		if (value === '') {
			setOptions(null);
		}
	}, [value]);
	useEffect(() => {
		if (extOpts) setOptions(extOpts as TGroupedOpts);
	}, [extOpts]);

	return (
		<TypeAhead
			{...rest}
			name={name}
			id={id}
			value={value}
			onChange={(e:ChangeEvent<HTMLInputElement>) => { updateValue(e) }}
			onSelected={(v:TypeAheadOption | string, p?:{ group: string }) => { updateSelected(v, p) }}
			options={options}
			isLoadingOptions={loadingAutocomplete}
		/>
	);
}
