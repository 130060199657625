import { useEffect } from 'react';
import styles from './MainLayout.module.scss';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { TopNavigation, SideNavigation } from '@components';
import { useRecoilValue, useRecoilState } from 'recoil';
import SuspenseLoader from '@pages/SuspenseLoader';
import {
	SideNavOpen,
	IsAdmin,
	JWT,
	UserMenuOpen,
} from '@state';
import useInitAuthenticatedUser from '@hooks/useInitAuthenticatedUser.ts';

type LayoutProps = {
	requireAdmin?: boolean;
	initComplete: boolean;
}

const MainLayout = (props:LayoutProps) => {
	const { requireAdmin = false, initComplete } = props;
	const navigate = useNavigate();
	const location = useLocation();
	const sideNavOpen = useRecoilValue(SideNavOpen);
	const [userMenuOpen, setUserMenuOpen] = useRecoilState(UserMenuOpen);
	const isAdmin = useRecoilValue(IsAdmin);
	const jwt = useRecoilValue(JWT);
	useInitAuthenticatedUser();

	useEffect(() => {
		if (requireAdmin && !isAdmin && initComplete) navigate(`/login`, { replace: true });
	}, [requireAdmin, initComplete]);

	useEffect(() => {
		if (!jwt && initComplete) {
			navigate(`/login?backto=${location.pathname}${location.search}`, { replace: true });
		}
	}, [jwt, initComplete]);

	return (
		<>
			{!initComplete ?
				<SuspenseLoader /> :
				<div data-testid={'@Ofload-MainLayout'} className={styles.mainLayout}>
					<div className={`${styles.blurredbox} ${userMenuOpen ? styles.blurred : ''}`} onClick={() => { setUserMenuOpen(!userMenuOpen) }} />
					<SideNavigation />
					<TopNavigation />
					<div data-testid={'@Ofload-MainLayout-Content'} className={`${styles.content} ${sideNavOpen ? styles.padded : ''}`}>
						<Outlet />
					</div>
				</div>
			}
		</>
	);
}

export default MainLayout;
