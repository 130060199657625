import { Select } from '@components';
import { useEffect, useState } from 'react';
import { useImpersonation } from '@hooks';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
	ActiveShipper,
	authenticatedUser
} from '@state';
import { SelectOptions } from '@types';
import ApiClient from '@api/axiosSetup';

export const ShipperCostCenterSelector = () => {
	const authUser = useRecoilValue(authenticatedUser);
	const [shippers, setShippers] = useState<SelectOptions[]>([]);
	const [activeShipper] = useRecoilState(ActiveShipper);
	const { impersonate } = useImpersonation();

	const getShippers = () => {
		ApiClient.get('/api/shipper/cost-center-options')
			.then((res) => {
				if (res.data) {
					setShippers(res.data.data);
				}
			}
		);
	}

	useEffect(() => {
		if (authUser?.shipper?.cost_center_id) {
			getShippers();
		}
	}, [authUser]);

	return (
		<>
			{shippers?.length > 0 &&
				<Select
					name={'shipper_id_selector'}
					placeholder={'Please select a shipper'}
					displayThin
					borderless
					value={activeShipper?.id ?? authUser?.shipper?.id}
					onChange={(value: number) => {
						impersonate('shipper', value);
					}}
				>
					{
						shippers?.map((shipper: SelectOptions, index: number) => (
							<Select.Option key={index} value={shipper.value}>
								{shipper.label}
							</Select.Option>)
						)
					}
				</Select>
			}
		</>
	)
}