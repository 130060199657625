// import { SelectOptions, TEMP_NAMES, TRUCK_OPTIONs } from '../../../types';

import { FREIGHT_MODE, TEMP_NAMES, TRUCK_OPTIONS } from '@types';
import { SelectOptions } from '@types';

export interface Range {
    start: number;
    end: number;
}

export type CustomerType = 'carrier' | 'shipper';
export type ShipmentApiCustomerType = 'Carrier' | 'Shipper';

export interface User {
    external_id: string;
    name?: string;
}

export type RangeKeys = keyof Range;
export type RateCardType = 'WEIGHT' | 'PALLETS' | 'VOLUME' | 'HOURS' | 'DISTANCE' | 'FULL_LOAD' | 'CPK';
export interface RangeCollection {
    type: RateCardType;
    canEdit?: boolean;
    sequence: Range[];
    truckSizesWithRate?: string[];
}
export enum RateCardTypeForPricing {
    PALLETS = 'Pallets',
    WEIGHT = 'Weight',
    VOLUME = 'Volume',
    HOURS = 'Hours',
    DISTANCE = 'Distance',
    FULL_LOAD = 'FullLoad',
    CPK = 'CPK',
}

export type RateCardTypeDetail = {
    type: RateCardType;
    title: string;
    subTitle: string;
    unit: string;
};
export type RateCardSettingsStates = 'incomplete' | 'complete-no-rate-cards' | 'has-rate-cards';
export interface Modifier {
    id: number;
    name: string;
    label: string;
    value: number;
    type: 'percent' | 'fixed';
}

export interface ModifierGroup {
    name: string;
    label: string;
    modifiers: Modifier[];
}

export interface DurationOption {
    label: 'Days' | 'Weeks' | 'Months';
    value: 'Days' | 'Weeks' | 'Months';
    daysMultiplier: number;
}
export type DurationValue = { value: number; duration: DurationOption['value'] };
export interface Rule {
    name: string;
    label: string;
    value?: number | string | boolean | DurationValue;
    type: 'duration' | 'boolean' | 'conversion' | 'value' | 'number';
}
export interface RuleGroup {
    name: string;
    label: string;
    rules: Rule[];
}

/*
 * export type FuelLevy = {
 *     value: number | string;
 *     useOwnFuelLevy?: boolean;
 *     fuel_levy_type?: 'global' | 'customer' | 'rate_card';
 *     start_date?: string;
 * };
 */

export type TLane = {
	id: string
	locations_id: string
	external_id: string
	customer_external_id: string
	customer_name?: string
	end_date: string
	freight_mode: FREIGHT_MODE;
	fuel_levy: TFuelLevy
	modifiers_type: string[]
	rate_card_types: string[]
	temperatures: string[]
	full_load: string[]
	nick_name: string
	description?: string
	sales_owner_id?: string
	ops_owner_id?: string
	origin: string
	destination: string
	disable_take_now_price: string
	ongoing_ratecard: string
	has_special_characteristics: string
	is_4pl: string
}

export type TFuelLevyTariff = {
	id: string,
	start_at: string;
	tariff: number;
	fuel_levy_id?: string;
}

export type TFuelLevy = {
	id: string;
	type: TFuelLevyType;
	customer_external_id?: string;
	current?: TFuelLevyTariff;
	next?: TFuelLevyTariff;
	updated_at?: string;
}

export enum RateCardPermissions {
    RATE_CARD_MANAGER = 'ratecard manager',
}

export type TFuelLevyType = 'global' | 'customer' | 'rate_card';
export interface RateCardSetting {
    state: RateCardSettingsStates;
    ranges: RangeCollection[];
    truckSizes: string[];
    permissions: RateCardPermissions[];
    settings: {
        /*
         * fuelLevies: {
         *     global: TScheduledFuelLevy;
         *     customer?: TScheduledFuelLevy;
         *     rate_card?: TScheduledFuelLevy;
         * };
         */
        fuelLevies: Partial<Record<TFuelLevyType, TFuelLevy>>;
        fuelLevyType: TFuelLevyType;
        // globalFuelLevyValue?: number;
        modifierGroups: ModifierGroup[];
        ruleGroups?: RuleGroup[];
        expirationDate?: string | null;
        minimumBillableHours?: number | null;
        isContract?: boolean;
        disableTakeNowPrice?: boolean;
        isOngoingRateCard?: boolean;
    };
}

export type PriceKeys = `${TEMP_NAMES}` | 'ftl';

export interface RateCardTable {
    type: RateCardType | '';
    rangePrices: Record<string, Record<PriceKeys, number>>;
    margins?: Record<TEMP_NAMES, number>;
    isContract?: boolean;
    is_4pl?: boolean;
    isOngoingRateCard?: boolean;
    extra_attributes?: {
        priced_annualized_volume?: number;
        priced_margin?: number;
    };
}

export interface LaneRateCards {
    id: string;
    rateCards: RateCardTable[];
    isLaneSettings: boolean;
    settings: Omit<RateCardSetting['settings'], 'fuelLevies'> & { fuelLevies: Partial<RateCardSetting['settings']['fuelLevies']>};
    origin: string;
    destination: string;
    description?: RateCardDescription;
    owners?: {
        operation?: User;
        sales?: User;
    };
	has_special_characteristics?: boolean;
}

export enum TruckSizes {
    RIGID = TRUCK_OPTIONS.RIGID,
    SINGLE = TRUCK_OPTIONS.SINGLE,
    A_DOUBLE = TRUCK_OPTIONS.A_DOUBLE,
    B_DOUBLE = TRUCK_OPTIONS.B_DOUBLE,
    B_DOUBLE_HIGH_CUBE = TRUCK_OPTIONS.B_DOUBLE_HIGH_CUBE,
    CONTAINER_20_FT_COASTAL = TRUCK_OPTIONS.FT20_CONTAINER,
    CONTAINER_40_FT_COASTAL = TRUCK_OPTIONS.FT40_CONTAINER,
    CONTAINER_46_FT_COASTAL = '46ft Container',
    CONTAINER_48_FT_COASTAL = '48ft Container',
    SUPER_B_DOUBLE = TRUCK_OPTIONS.SUPER_B_DOUBLE,
    B_TRIPLE = TRUCK_OPTIONS.B_TRIPLE,
}

export interface RateCardDescription {
    nickName?: string;
    description?: string;
}
export interface PricingData {
    rateCardId: string;
    rateCardType: string;
    rateCardMode: string;
    price: {
        tariff: Tariff;
        totalPrice: string;
        subTotalPrice: string;
        basePrice: string;
    };
    fuelLevy: {
        tariff: string;
        type: string;
    };
    rule: any;
    modifiers: any[];
    totalPrice?: number;
    rateCardExternalId?: string;
}

export interface Tariff {
    value: string;
    margin: string;
    cost: string;
}

export const TruckOptionsToTruckSize = (option: TRUCK_OPTIONS): keyof typeof TruckSizes | '' => {
	switch (option) {
	case TRUCK_OPTIONS.RIGID:
		return 'RIGID';
	case TRUCK_OPTIONS.SINGLE:
		return 'SINGLE';
	case TRUCK_OPTIONS.A_DOUBLE:
		return 'A_DOUBLE';
	case TRUCK_OPTIONS.B_DOUBLE:
		return 'B_DOUBLE';
	case TRUCK_OPTIONS.B_DOUBLE_HIGH_CUBE:
		return 'B_DOUBLE_HIGH_CUBE';
	case TRUCK_OPTIONS.FT20_CONTAINER:
	case TRUCK_OPTIONS.FT20_Container_SERVER:
		return 'CONTAINER_20_FT_COASTAL';
	case TRUCK_OPTIONS.FT40_CONTAINER:
	case TRUCK_OPTIONS.FT40_Container_SERVER:
		return 'CONTAINER_40_FT_COASTAL';
	case TRUCK_OPTIONS.FT46_CONTAINER:
		return 'CONTAINER_46_FT_COASTAL';
	case TRUCK_OPTIONS.FT48_CONTAINER:
		return 'CONTAINER_48_FT_COASTAL';
	case TRUCK_OPTIONS.B_TRIPLE:
		return 'B_TRIPLE';
	case TRUCK_OPTIONS.SUPER_B_DOUBLE:
		return 'SUPER_B_DOUBLE';
	case TRUCK_OPTIONS.B_DOUBLE_SUPER_HIGH_CUBE:
		return '';
	default:
		return '';
	}
};

export const CubicConversionDropdownOptions: SelectOptions[] = [
	{ label: '1000', value: 1000 },
	{ label: '333', value: 333 },
	{ label: '250', value: 250 },
	{ label: '200', value: 200 },
	{ label: '190', value: 190 },
	{ label: '167', value: 167 },
	{ label: '150', value: 150 },
	{ label: '1', value: 1 },
];
