import { useNavigate, useSearchParams } from 'react-router-dom';
import { CarrierSearch, ImpersonatedCarrier, ImpersonatedShipper, ShipperSearch, TJWT, } from '@types';
import { ActiveCarrier, ActiveShipper, JWT } from '@state';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import ApiClient from '@api/axiosSetup';
import { jwtDecode } from 'jwt-decode';

const IMPERSONATION_LOCAL_STORAGE_KEY = {
	carrier: 'a1f65eb1-2717-4edf-97fd-5a07ddb27ced2',
	shipper: 'ac63ba8e-2d80-4145-8b98-73c5baa7dbbb2',
};

type ActiveShipperTokenResp = {
	id: number;
	customer_id: string;
	token: number;
	business_name: string;
}

const getIdOrExternalId = (id: null|string): number|string|null => {
	if (id === null) {
		return null;
	}

	const shipperId = parseInt(id, 10);
	if (!isNaN(shipperId) && shipperId >= 0) {
		return shipperId;
	}

	if (id.startsWith('s') || id.startsWith('c')) {
		return id;
	}
	return null;
}

const fetchShipperById = async (id: number|string): Promise<ImpersonatedShipper> => {
	try {
		const response = await ApiClient.post(`/api/shipper/get-active-shipper-token`, { shipperId: id });
		const fetchedShipper = response.data.data as ActiveShipperTokenResp;
		return {
			id: fetchedShipper.id,
			customer_id: fetchedShipper.customer_id,
			name: fetchedShipper.business_name,
			label: fetchedShipper.business_name,
			token: fetchedShipper.token.toString(),
			type: 'shipper',
		};
	} catch (error) {
		console.error(`Failed to fetch shipper with ID ${id}:`, error);
		throw error;
	}
};

export const useImpersonation = () => {
	const [search, setSearch] = useSearchParams();
	const navigate = useNavigate();
	const sid = search.get('sid');
	const cid = search.get('cid');
	const storedSid = sessionStorage.getItem(IMPERSONATION_LOCAL_STORAGE_KEY.shipper);
	const storedCid = sessionStorage.getItem(IMPERSONATION_LOCAL_STORAGE_KEY.carrier);
	const setActiveShipper = useSetRecoilState(ActiveShipper);
	const setActiveCarrier = useSetRecoilState(ActiveCarrier);
	const jwt = useRecoilValue(JWT);
	const shipperIdOrExternalId = getIdOrExternalId(sid);

	const canImpersonate = () => {
		if (jwt) {
			const decoded: TJWT = jwtDecode(jwt);
			if (decoded.user_type === 'admin') {
				return true;
			}
	
			if (decoded.user_type === 'shipper'
				 && decoded.customer_ids && decoded.customer_ids.length > 1
			) {
				return true;
			}
		}

		return false;
	}

	const impersonate = async (
		userType: 'carrier' | 'shipper' | null = null,
		impersonation: ShipperSearch | CarrierSearch | number | null = null
	) => {
		if (!canImpersonate()) {
			return;
		}

		if (!userType) {
			if (shipperIdOrExternalId !== null) {
				if (storedSid !== null) {
					const activeShipper: ImpersonatedShipper = JSON.parse(storedSid);
					if (activeShipper.id !== shipperIdOrExternalId && activeShipper.customer_id !== shipperIdOrExternalId) {
						try {
							const shipper = await fetchShipperById(shipperIdOrExternalId);
							sessionStorage.setItem(IMPERSONATION_LOCAL_STORAGE_KEY.shipper,
								JSON.stringify(shipper));
							setActiveShipper(shipper);
						} catch (error) {
							console.error('Failed to fetch shipper:', error);
							throw error;
						}
					} else {
						setActiveShipper(activeShipper);
					}
				} else {
					try {
						const shipper = await fetchShipperById(shipperIdOrExternalId);
						sessionStorage.setItem(IMPERSONATION_LOCAL_STORAGE_KEY.shipper,
							JSON.stringify(shipper));
						setActiveShipper(shipper);
					} catch (error) {
						console.error('Failed to fetch shipper:', error);
					}
				}
			} else if (storedSid !== null) {
				const activeShipper: ImpersonatedShipper = JSON.parse(storedSid);
				setSearch((params) => {
					params.set('sid', `${activeShipper.id}`)
					return params
				});
				setActiveShipper(activeShipper);
			}

			if (cid !== null) {
				if (storedCid !== null) {
					const activeCarrier: ImpersonatedCarrier = JSON.parse(storedCid);
					if (activeCarrier.id !== parseInt(cid)) {
						// --> fetch Active Carrier via CID
					} else {
						setActiveCarrier(activeCarrier);
					}
				} else {
					// --> fetch Active Carrier via CID;
				}
			} else if (storedCid !== null) {
				const activeCarrier: ImpersonatedCarrier = JSON.parse(storedCid);
				setSearch((params) => {
					params.set('cid', `${activeCarrier.id}`)
					return params
				});
				setActiveCarrier(activeCarrier);
			}
		} else if (userType === 'carrier' && impersonation && typeof impersonation === 'object') {
			const carrier: ImpersonatedCarrier = {
				id: (impersonation as CarrierSearch).carrier_id!,
				customer_id: (impersonation as CarrierSearch).customer_external_id!,
				name: (impersonation as CarrierSearch).bus_name!,
				label: (impersonation as CarrierSearch).bus_name!,
				token: (impersonation as CarrierSearch).carrier_id!.toString(),
				type: 'carrier',
			}
			setActiveCarrier(carrier);
			sessionStorage.setItem(IMPERSONATION_LOCAL_STORAGE_KEY.carrier, JSON.stringify(carrier));
			setSearch((params) => {
				params.set('cid', `${carrier.id}`)
				return params
			});
		} else if (userType === 'shipper' && impersonation) {
			if (typeof impersonation === 'number') {
				const shipper = await fetchShipperById(impersonation);
				setActiveShipper(shipper);
				sessionStorage.setItem(IMPERSONATION_LOCAL_STORAGE_KEY.shipper, JSON.stringify(shipper));
				setSearch((params) => {
					params.set('sid', `${shipper.id}`)
					return params
				});
			} else {
				const shipper: ImpersonatedShipper = {
					id: (impersonation as ShipperSearch).shipper_id,
					customer_id: (impersonation as ShipperSearch).customer_external_id!,
					name: (impersonation as ShipperSearch).bus_name!,
					label: (impersonation as ShipperSearch).bus_name!,
					token: (impersonation as ShipperSearch).shipper_id!.toString(),
					type: 'shipper',
				}
				setActiveShipper(shipper);
				sessionStorage.setItem(IMPERSONATION_LOCAL_STORAGE_KEY.shipper, JSON.stringify(shipper));
				setSearch((params) => {
					params.set('sid', `${shipper.id}`)
					return params
				});
			}
		}
	}

	const refrain = (userType: 'carrier' | 'shipper' | null = null) => {
		if (userType) {
			if (userType === 'carrier') {
				sessionStorage.removeItem(IMPERSONATION_LOCAL_STORAGE_KEY.carrier);
				setActiveCarrier(null);
				if (search.has('cid')) {
					search.delete('cid');
					setSearch(search);
				}
				navigate('/carrier/dashboard');
			} else {
				sessionStorage.removeItem(IMPERSONATION_LOCAL_STORAGE_KEY.shipper);
				setActiveShipper(null);
				if (search.has('sid')) {
					search.delete('sid');
					setSearch(search);
				}
				window.location.href = '/admin/shipper/overview';
			}
		} else {
			sessionStorage.removeItem(IMPERSONATION_LOCAL_STORAGE_KEY.carrier);
			sessionStorage.removeItem(IMPERSONATION_LOCAL_STORAGE_KEY.shipper);
			setActiveShipper(null);
			setActiveCarrier(null);
		}
	}

	return {
		impersonate,
		refrain,
		canImpersonate,
	}
}
