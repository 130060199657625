import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { navigator } from '@types';

const financeNav = () => {
	const root = `/finance`;
	return [
		{
			label: 'Shipper Billing',
			icon: (color: string) => <ReceiptOutlinedIcon style={{ color: color }}/>,
			link: `/finance/shipper_billing`,
			enabled: true,
			domain: 'spa',
			activeURL: 'shipper_billing'
		},
		{
			label: 'Carrier Billing',
			icon: (color: string) => <ReceiptLongOutlinedIcon style={{ color: color }}/>,
			link: `/finance/carrier_billing`,
			enabled: true,
			domain: 'spa',
			activeURL: 'carrier_billing'
		},
		{
			label: 'Issues & Claims',
			icon: (color: string) => <AssignmentLateOutlinedIcon style={{ color: color }} />,
			link: `/finance/issues`,
			enabled: true,
			domain: 'spa',
			activeURL: 'issues'
		},
		{
			label: 'Invoice Report',
			icon: (color: string) => <VerifiedUserOutlinedIcon style={{ color: color }} />,
			link: `/finance/invoice_report`,
			enabled: true,
			domain: 'spa',
			activeURL: 'invoice_report'
		},
		{
			label: 'Invoice Management',
			icon: (color: string) => <CurrencyExchangeIcon style={{ color: color }}/>,
			link: `${root}/invoice_management`,
			enabled: true,
			domain: 'spa',
			activeURL: 'invoice_management'
		},
	] as navigator[];
}

export default financeNav;
